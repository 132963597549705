<template>
  <div>    
    <SupportedLiving />
  </div>
</template>
<script>
import SupportedLiving from "../components/SupportedLiving";
export default {
  components: {    
    SupportedLiving,
  },
};
</script>