<template>
  <div class="service-area pt--70 pb--50 bg_color--1">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center section-title mb--30">
            <h2 class="heading-title">
              <span class="app-title-text-left">Supported</span> Living
            </h2>
            <p class="description text-left">
              We have adopted a person-centred approach that derives its
              effectiveness from our commitment to ensure the person we support
              is heard and offered opportunities, enhancing their wellbeing. We
              derive our motivation from seeing people develop and improve their
              lives and becoming active in their local community as they engage
              in activities that interest them.
            </p>
            <p class="description text-left">
              We identify people's outcomes and tailor their support to reflect
              what the person wants, requires, or expects. We are set up on a
              value base that places people we support at the centre of all
              efforts to support them to design and direct their own support.
              Through our Outcomes Based Support, people we support have gained
              independence in improving and maintaining their independence at
              home and in the community.
            </p>
            <p class="description text-left">
              <b>Quality service:</b>
              At Rehoboth24 Healthcare Solutions, we understand the service
              concept, to support people to maintain, regain independence and
              learn new skills through support and encouragement as they lead
              their day-to-day lives. We take a person-centred approach and
              understand that each person will require bespoke support. We set
              expectations from the outset, helping service users to understand
              that our service is focused on supporting them to achieve their
              outcomes in line with their wishes
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="service-main-wrapper bg-white--service">
        <v-col
          lg="4"
          md="4"
          sm="6"
          cols="12"
          v-for="(listGroup, i) in listInfo"
          :key="i"
        >
          <div class="service service__style--2 text-left bg-gray">
            <div class="service">
              <div class="icon">
                <div v-html="iconSvg(listGroup.icon)"></div>
              </div>
              <div class="content">
                <h3 class="heading-title">{{ listGroup.title }}</h3>
                <p v-html="listGroup.text"></p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      listInfo: [
        {
          icon: "home",
          title: "Supported Living Services",
          text: `Fostering Independence: All of our innovative services are focused on supporting people to be as independent within their own homes and in their local community.`,
        },
        {
          icon: "layers",
          title: "Learning Disability Support",
          text: `Successful and independent living: At Hillside Care we support people with learning disabilities, we help individuals with their learning and are there to support them.`,
        },
        {
          icon: "users",
          title: "Autism Care and Support",
          text: `For a more progressive and secured future: Hillside Care offers its services in the UK for autistic patients to provide them with support. Autism impairs the ability to interact with others.`,
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
